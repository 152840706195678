import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "indexPage",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "logo",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png'),
      "alt": ""
    }
  }), _vm._v(" MVNT ")])]), _c('div', {
    staticClass: "p-box"
  }, [_vm._m(0), _c('div', {
    staticClass: "p2"
  }, [_c('div', {
    staticClass: "p2-main"
  }, [_c('div', {
    staticClass: "logo2"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo-2.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "p2-title"
  }, [_vm._v("MVNT STAKING POOL")]), _c('div', {
    staticClass: "p2-subtitle"
  }, [!_vm.isMobile ? [_vm._v(" Easy staking, easy earning!")] : [_vm._v("Easy staking,easy earning!")]], 2), _vm._m(1), _c('div', {
    staticClass: "p2-btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/stake');
      }
    }
  }, [_vm._v("STAKING "), _c('svg-icon', {
    attrs: {
      "name": "icon-bottom-arrow"
    }
  })], 1)])])])]), _vm._m(2), _vm._m(3)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p1"
  }, [_c('div', {
    staticClass: "p1-main"
  }, [_c('div', {
    staticClass: "p1-intro"
  }, [_c('div', {
    staticClass: "p1-intro__title"
  }, [_c('i', [_vm._v("THE")]), _vm._v(" WORLD'S "), _c('p', [_vm._v("FIRST MEME STABLECOIN")])]), _c('div', {
    staticClass: "p1-intro__subtitle"
  }, [_vm._v(" SHAPING THE SOUTHEAST "), _c('p', [_vm._v("ASIAN VERSION OF USDT")])]), _c('div', {
    staticClass: "p1-intro__label"
  }, [_vm._v(" Pioneering a stable digital ecosystem, "), _c('p', [_vm._v("MVNT leads the blockchain trend in Southeast Asia.")])]), _c('div', {
    staticClass: "p1-intro__msc"
  }, [_vm._v(" MVNT, the world’s first Meme stablecoin that aims to shape the Southeast Asian version of USDT, "), _c('p', [_vm._v("is dedicated to offering users a stable and efficient digital currency experience.")]), _c('p', [_vm._v("With MVNT, you can seamlessly stake USDT and earn stable MVNT token rewards,")]), _c('p', [_vm._v(" all while enjoying the convenience of multiple application scenarios")])])]), _c('div', {
    staticClass: "p1-img"
  }, [_c('div', {
    staticClass: "p1-img-main"
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2-list"
  }, [_c('div', {
    staticClass: "p2-item"
  }, [_vm._v(" Flexible Staking APY "), _c('div', {
    staticClass: "p2-item__value"
  }, [_vm._v("15%")])]), _c('div', {
    staticClass: "p2-item"
  }, [_c('span', [_vm._v(" 3-month Fixed Staking APY ")]), _c('div', {
    staticClass: "p2-item__value"
  }, [_vm._v("20%")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p3"
  }, [_c('div', {
    staticClass: "p3-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("VERSATILE UTILITIES: ONE COIN, MANY BENEFITS")]), _c('div', {
    staticClass: "subtitle"
  }, [_vm._v("Effortlessly Achieve Versatile Utilities Via Diverse Applications")]), _c('div', {
    staticClass: "small"
  }, [_vm._v("MVNT serves not only as a stable digital asset but also finds utility across various scenarios")]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item__img"
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v("Cash Withdrawal")]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v("Convert MVNT into fiat currency for easy withdrawal anytime & anywhere, with only a 10% fee.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item__img"
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v("Shopping")]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v("Enjoy discounts and benefits by using MVNT for purchases at partner merchants.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item__img"
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v("Offline Transactions")]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v("Conduct convenient transactions with MVNT at designated offline merchants.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item__img"
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v("Re-Staking")]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v("Re-stake earned MVNT to achieve higher returns and asset appreciation.")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p4"
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("MVNT，Expanding digital asset horizons，pioneering a new crypto lifestyle!")]), _c('div', {
    staticClass: "subtitle"
  }, [_vm._v("Take action now and embark on your MVNT journey!")])])]);
}];
export { render, staticRenderFns };