/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./stake.vue?vue&type=template&id=80e24d3c&scoped=true"
import script from "./stake.vue?vue&type=script&lang=js"
export * from "./stake.vue?vue&type=script&lang=js"
import style0 from "./stake.vue?vue&type=style&index=0&id=80e24d3c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80e24d3c",
  null
  
)

export default component.exports