import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["setting", "isMobile"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  created() {
    // console.log("this.web3Wallet", this.web3Wallet);
    this.web3Wallet && this.web3Wallet.initWeb3Modal();
  }
};