import { createNamespacedHelpers } from "vuex";
import BigNumber from "bignumber.js";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "stake",
  computed: {
    ...walletVuex.mapState(['isMobile', 'connect', 'isAddress', 'netIdError', 'address']),
    ...walletVuex.mapGetters(['isWallet'])
  },
  data() {
    const tab = [{
      label: this.$lang("Deposit and withdraw at Any Time"),
      value: 0
    }, {
      label: this.$lang("Deposit and withdraw at Three Months"),
      value: 1
    }];
    return {
      tab,
      selectTab: 0,
      num: 0,
      rate: 15,
      pretoken: 23000,
      timeVal: false,
      isApprove: false,
      stakeUsdtTotalLabel: 0,
      usdtBalance: 0,
      usdtBalanceLabel: 0,
      preDayRewards: 0,
      userStakeList: [],
      userClaimTotal: 0,
      nowTime: 0
    };
  },
  watch: {
    isWallet: {
      async handler(bool) {
        //验证链接和登录状态
        if (bool) {
          this.initData();
        }
      },
      immediate: true,
      deep: true
    },
    connect: {
      async handler(bool) {
        this.initData();
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (localStorage.getItem('WALLET_CONNECTED') == true || localStorage.getItem('WALLET_CONNECTED') == 'true') {
      this.doConnect();
    }
    this.initData();
  },
  methods: {
    ...walletVuex.mapActions(['doConnect']),
    onTab(item) {
      this.selectTab = item.value;
      if (this.selectTab == 0) {
        this.rate = 15;
      } else {
        this.rate = 20;
      }
      this.calcRewards();
    },
    onAll() {
      this.num = this.usdtBalanceLabel;
      this.calcRewards();
    },
    async checkApprove() {
      if (!this.address) return;
      let allowance = await this.web3Wallet.contract("token", this.web3Config.usdt_contract).call("allowance", [this.address, this.web3Config.staking_contract]);
      if (new BigNumber(allowance).lt(new BigNumber(this.num).times(10 ** this.web3Config.usdt_decimals))) {
        this.isApprove = false;
      } else {
        this.isApprove = true;
      }
    },
    async doApprove() {
      await this.web3Wallet.contract("token", this.web3Config.usdt_contract).send("approve", [this.web3Config.staking_contract, this.usdtBalance]);
      this.checkApprove();
    },
    calcRewards() {
      this.preDayRewards = new BigNumber(this.num).times(this.pretoken).times(this.rate).div(100).div(365).toFixed(5);
      this.checkApprove();
    },
    async initData() {
      clearTimeout(this.timeVal);
      const [usdtBalance, stakeUsdtTotal, userClaimTotal, userStakeList, getTime] = await Promise.all([this.address ? this.web3Wallet.contract("token", this.web3Config.usdt_contract).call("balanceOf", [this.address]) : 0, this.web3Wallet.contract("staking").call("stakeUsdtTotal", []), this.address ? this.web3Wallet.contract("staking").call("userClaimTotal", [this.address]) : 0, this.address ? this.web3Wallet.contract("staking").call("getList", [this.address]) : [], this.web3Wallet.contract("staking").call("getTime", [])]);
      this.nowTime = new BigNumber(getTime).toFixed(0);
      this.usdtBalance = new BigNumber(usdtBalance).toFixed(0);
      this.usdtBalanceLabel = new BigNumber(usdtBalance).div(10 ** this.web3Config.usdt_decimals).toFixed(2);
      this.stakeUsdtTotalLabel = new BigNumber(stakeUsdtTotal).div(10 ** this.web3Config.usdt_decimals).toFixed(2);
      this.userClaimTotal = new BigNumber(userClaimTotal).div(10 ** this.web3Config.coin_decimals).toFixed(2);
      this.userStakeList = userStakeList.map((item, index) => {
        item.usdtTotalLabel = new BigNumber(item.usdtTotal).div(10 ** this.web3Config.usdt_decimals).toFixed(2);
        item.rateLabel = new BigNumber(item.rate).div(100).toFixed(0);
        item.claimTime = new BigNumber(item.claimTime).toFixed(0);
        let leftTime = new BigNumber(this.nowTime).minus(item.stakeTime).toFixed(0);
        if (item.claimTime > 0) {
          item.reward = new BigNumber(item.claimTotal).div(10 ** this.web3Config.coin_decimals).toFixed(5);
        } else {
          item.reward = new BigNumber(item.usdtTotal).div(10 ** this.web3Config.usdt_decimals).times(leftTime).times(item.preToken).times(item.rate).div(10000).div(365).div(86400).toFixed(5);
        }
        if (item.stakeType == 0) {
          item.stakeTypeLabel = "Any Time";
        } else {
          item.stakeTypeLabel = "Three Months";
        }
        item.stakeTime = new BigNumber(item.stakeTime).toFixed(0);
        item.unStakeTime = new BigNumber(item.unStakeTime).toFixed(0);
        item.idx = index;
        return item;
      });
      this.userStakeList.reverse();
      this.calcRewards();
      this.timeVal = setTimeout(() => {
        this.initData();
      }, 10000);
    },
    async doStake() {
      if (Number(this.num) <= 0) {
        this.$toasted.error("Please input stake amount");
        return false;
      }
      let stakamount = new BigNumber(this.num).times(10 ** this.web3Config.usdt_decimals).toFixed(0);
      await this.web3Wallet.contract("staking").send("stake", [stakamount, this.selectTab]);
      this.initData();
    },
    async unStake(item) {
      if (item.unStakeTime > this.nowTime || item.claimTime > 0) {
        return false;
      }
      await this.web3Wallet.contract("staking").send("claim", [item.idx]);
    }
  }
};